import React from "react";
import "./GamePage.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import imgOne from "../../images/slashimi-1.webp";
import imgTwo from "../../images/slashimi-2.webp";

const GameOne = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/slashimi" && (
        <Helmet>
          <title>Slashimi - Duik in de opwinding van sushi-plezier!</title>
          <meta
            name="description"
            content="Ervaar de spanning van sushi-gethematiseerd gamen met Slashimi! Snijd door levendige symbolen, win groot en geniet van een leuk avontuur met elke spin."
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-game-page">
          <div className="topcasinonl-game-page__body pad">
            <h1 className="text-center topcasinonl-main__header col-black">
              Slashimi
            </h1>
            <iframe
              title="Slashimi Overview"
              className="topcasinonl-game-page__frame"
              src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%25&amp;height=100%25&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False&amp;country=&amp;pauseplay=&amp;playlimit=&amp;selftest=&amp;sessiontime=&amp;gid=slashimi"
            ></iframe>
          </div>
        </section>
        <section className="topcasinonl-advantages pad">
          <h2>Over het spel</h2>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Beschrijving</p>
            <p>
              Slashimi is een online slot gemaakt door Play’n Go. De
              belangrijkste features zijn gratis spins en de standaard Scatter
              en Wild symbolen.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Thema en afbeeldingen</p>
            <p>
              In de Slashimi online slot krijg je de eerste rij in een klein en
              gezellig sushi restaurant, een verborgen pareltje in de trendy
              wijk Shibuya in Tokio, of het legendarische Dotonbori in Osaka.
              Spelers ontmoeten de bekwame chef, Jiro Miike, terwijl hij het
              volgende gerecht klaarmaakt om te serveren.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">RTP en variantie</p>
            <p>
              De RTP van Slashimi moet nog worden aangekondigd. Het is niet
              ongebruikelijk dat RTP's van online slots pas worden aangekondigd
              als het spel live gaat, en we zullen deze review
              dienovereenkomstig bijwerken. Het spel heeft een gemiddelde tot
              hoge volatiliteit, dus je kunt mooi uitgebalanceerde periodes
              verwachten met meer uitdagende periodes. De maximale winst in de
              Slashimi-slot is gemaximeerd op 5.000x de inzet.
            </p>
          </div>
          <div className="topcasinonl-grid topcasinonl-game-page__img">
            <img src={imgOne} title="slashimi game 1" alt="slashimi game 1"/>
            <img src={imgTwo} title="slashimi game 2" alt="slashimi game 2"/>
          </div>
        </section>
      </main>
    </>
  );
};

export default GameOne;
