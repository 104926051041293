import React, { useState } from "react";
import "./Casinos.css";
import closeBtn from "../../images/close-btn.svg";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Casinos = () => {
  const [isGameOneVisible, setIsGameOneVisible] = useState(false);
  const [isGameTwoVisible, setIsGameTwoVisible] = useState(false);
  const [isGameThreeVisible, setIsGameThreeVisible] = useState(false);
  const [isGameFourVisible, setIsGameFourVisible] = useState(false);
  const location = useLocation();

  return (
    <>
      {location.pathname === "/games" && (
        <Helmet>
          <title>
            De beste nieuwe en veilige online casinospellen in Nederland - 2025
          </title>
          <meta
            name="description"
            content="Speel de 4 beste online casinospellen van 2025 in Nederland! Ontdek spannende spellen, eerlijke beoordelingen en betrouwbare platforms met licentie."
          />
        </Helmet>
      )}
      <section className="topcasinonl-flex pad mg-top">
        {location.pathname === "/games" ? (
          <h1>Onze spellen</h1>
        ) : (
          <h2>Onze spellen</h2>
        )}
        <ul className="topcasinonl-casinos__grid">
          <li className="topcasinonl-casinos__game-body">
            <div className="topcasinonl-casinos__game">
              <div className="topcasinonl-casinos__game-img game-1">
                <div className="topcasinonl-casinos__overlay">
                  <p className="t-20 text-center">
                    Slashimi
                  </p>
                  <div className="topcasinonl-casinos__buttons">
                    <button
                      className="btn"
                      onClick={() => setIsGameOneVisible(true)}
                    >
                      Speel nu
                    </button>
                    <Link
                      to="/slashimi"
                      className="btn topcasinonl-casinos__more-info"
                    >
                      Beschrijving
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <p className="t-20">Slashimi</p>
          </li>
          <li className="topcasinonl-casinos__game-body">
            <div className="topcasinonl-casinos__game">
              <div className="topcasinonl-casinos__game-img game-2">
                <div className="topcasinonl-casinos__overlay">
                  <p className="t-20 text-center">
                  Phoenix Up Cash
                  </p>
                  <div className="topcasinonl-casinos__buttons">
                    <button
                      className="btn"
                      onClick={() => setIsGameTwoVisible(true)}
                    >
                      Speel nu
                    </button>
                    <Link
                      to="/phoenix"
                      className="btn topcasinonl-casinos__more-info"
                    >
                      Beschrijving
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <p className="t-20">Phoenix Up Cash</p>
          </li>
          <li className="topcasinonl-casinos__game-body">
            <div className="topcasinonl-casinos__game">
              <div className="topcasinonl-casinos__game-img game-3">
                <div className="topcasinonl-casinos__overlay">
                  <p className="t-20 text-center">
                  Bull in a Rodeo
                  </p>
                  <div className="topcasinonl-casinos__buttons">
                    <button
                      className="btn"
                      onClick={() => setIsGameThreeVisible(true)}
                    >
                      Speel nu
                    </button>
                    <Link
                      to="/bull"
                      className="btn topcasinonl-casinos__more-info"
                    >
                      Beschrijving
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <p className="t-20">Bull in a Rodeo</p>
          </li>
          <li className="topcasinonl-casinos__game-body">
            <div className="topcasinonl-casinos__game">
              <div className="topcasinonl-casinos__game-img game-4">
                <div className="topcasinonl-casinos__overlay">
                  <p className="t-20 text-center">
                  Action Bank
                  </p>
                  <div className="topcasinonl-casinos__buttons">
                    <button
                      className="btn"
                      onClick={() => setIsGameFourVisible(true)}
                    >
                      Speel nu
                    </button>
                    <Link
                      to="/action"
                      className="btn topcasinonl-casinos__more-info"
                    >
                      Beschrijving
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <p className="t-20">Action Bank</p>
          </li>
        </ul>

        {isGameOneVisible && (
          <div className="topcasinonl-game-popup">
            <div className="topcasinonl-popup__game">
              <img
                src={closeBtn}
                alt="close popup btn"
                title="close popup btn"
                className="topcasinonl-popup__close"
                onClick={() => setIsGameOneVisible(false)}
              />
              <iframe
                title="Slashimi frame"
                className="topcasinonl-game__frame"
                src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%25&amp;height=100%25&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False&amp;country=&amp;pauseplay=&amp;playlimit=&amp;selftest=&amp;sessiontime=&amp;gid=slashimi"
              ></iframe>
            </div>
          </div>
        )}
        {isGameTwoVisible && (
          <div className="topcasinonl-game-popup">
            <div className="topcasinonl-popup__game">
              <img
                src={closeBtn}
                alt="close popup btn"
                title="close popup btn"
                className="topcasinonl-popup__close"
                onClick={() => setIsGameTwoVisible(false)}
              />
              <iframe
                title="Phoenix Up Cash frame"
                className="topcasinonl-game__frame"
                src="https://d2drhksbtcqozo.cloudfront.net/casino/launcher.html?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;apex=1&amp;fullscreen=false"
              ></iframe>
            </div>
          </div>
        )}
        {isGameThreeVisible && (
          <div className="topcasinonl-game-popup">
            <div className="topcasinonl-popup__game">
              <img
                src={closeBtn}
                alt="close popup btn"
                title="close popup btn"
                className="topcasinonl-popup__close"
                onClick={() => setIsGameThreeVisible(false)}
              />
              <iframe
                title="Bull in a Rodeo frame"
                className="topcasinonl-game__frame"
                src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;gid=bullinarodeo&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%25&amp;height=100%25&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False"
              ></iframe>
            </div>
          </div>
        )}
        {isGameFourVisible && (
          <div className="topcasinonl-game-popup">
            <div className="topcasinonl-popup__game">
              <img
                src={closeBtn}
                alt="close popup btn"
                title="close popup btn"
                className="topcasinonl-popup__close"
                onClick={() => setIsGameFourVisible(false)}
              />
              <iframe
                title="Action Bank frame"
                className="topcasinonl-game__frame"
                src="https://dnk-resource.wimobile.casinarena.com/resource-service/game.html?game=actionbank_prt&amp;partnercode=mockpartner&amp;realmoney=false&amp;gaffing=true&amp;demo=true"
              ></iframe>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Casinos;
