import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import GameOne from "../GamePages/GameOne";
import GameTwo from "../GamePages/GameTwo";
import GameThree from "../GamePages/GameThree";
import GameFour from "../GamePages/GameFour";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Casinos from "../Casinos/Casinos";

function App() {
  return (
    <HelmetProvider>
      <div className="topcasinonl-page">
        <ScrollToTop />
        <Header />
        <Popup />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/games" element={<Casinos />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/slashimi" element={<GameOne />} />
          <Route path="/phoenix" element={<GameTwo />} />
          <Route path="/bull" element={<GameThree />} />
          <Route path="/action" element={<GameFour />} />
        </Routes>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;
