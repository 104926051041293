import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/privacy" && (
        <Helmet>
          <title>Privacybeleid voor topcasinonl.com</title>
          <meta
            name="description"
            content="Op deze pagina leest u ons privacybeleid en leert u hoe wij uw persoonlijke gegevens verzamelen, verwerken en beschermen. Wij bieden volledige privacy- en veiligheidsbeschermi"
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-privacy pad mg-top">
          <h1>Privacybeleid voor topcasinonl.com</h1>
          <p>
            Op topcasinonl.com, toegankelijk via topcasinonl.com, is een van
            onze belangrijkste prioriteiten de privacy van onze bezoekers. Dit
            Privacybeleid bevat de soorten informatie die worden verzameld en
            geregistreerd door topcasinonl.com en hoe wij deze gebruiken.
            <br />
            Als u aanvullende vragen heeft of meer informatie nodig heeft over
            ons Privacybeleid, neem dan gerust contact met ons op.
          </p>
          <h2>Logbestanden</h2>
          <p>
            topcasinonl.com volgt een standaardprocedure voor het gebruik van
            logbestanden. Deze bestanden registreren bezoekers wanneer ze
            websites bezoeken. Alle hostingbedrijven doen dit als onderdeel van
            de hostingdienstenanalyse. De informatie die door logbestanden wordt
            verzameld, omvat internetprotocol (IP)-adressen, browsertype,
            internetprovider (ISP), datum- en tijdstempel,
            verwijzende/exitpagina's en mogelijk het aantal klikken. Deze
            gegevens zijn niet gekoppeld aan enige persoonlijk identificeerbare
            informatie. Het doel van deze informatie is trendanalyse,
            sitebeheer, het volgen van gebruikersbewegingen op de website en het
            verzamelen van demografische informatie. Ons Privacybeleid is
            opgesteld met behulp van de Privacy Policy Generator.
          </p>
          <h2>Onze advertentiepartners</h2>
          <p>
            Sommige adverteerders op onze site kunnen cookies en webbakens
            gebruiken. Onze advertentiepartners zijn hieronder vermeld. Elk van
            onze advertentiepartners heeft zijn eigen Privacybeleid met
            betrekking tot het omgaan met gebruikersgegevens. Voor eenvoudigere
            toegang hebben we hyperlinks naar hun Privacybeleid hieronder
            toegevoegd.
          </p>
          <h2>Privacybeleid</h2>
          <p>
            U kunt deze lijst raadplegen om het Privacybeleid van elk van de
            advertentiepartners van topcasinonl.com te vinden.
            <br />
            Externe advertentienetwerken of advertentiebedrijven gebruiken
            technologieën zoals cookies, JavaScript of webbakens in hun
            respectieve advertenties en links die op topcasinonl.com
            verschijnen. Deze worden rechtstreeks naar de browser van gebruikers
            verzonden. Ze ontvangen automatisch uw IP-adres wanneer dit gebeurt.
            Deze technologieën worden gebruikt om de effectiviteit van
            advertentiecampagnes te meten en/of om de advertentie-inhoud die u
            op bezochte websites ziet te personaliseren.
            <br />
            Houd er rekening mee dat topcasinonl.com geen toegang heeft tot of
            controle heeft over deze cookies die door externe adverteerders
            worden gebruikt.
          </p>
          <h2>Privacybeleid van derden</h2>
          <p>
            Het Privacybeleid van topcasinonl.com is niet van toepassing op
            andere adverteerders of websites. Wij raden u daarom aan om het
            specifieke Privacybeleid van deze externe advertentienetwerken te
            raadplegen voor meer gedetailleerde informatie. Dit kan hun
            praktijken en instructies bevatten over hoe u zich kunt afmelden
            voor bepaalde opties.
            <br />U kunt ervoor kiezen om cookies uit te schakelen via de
            instellingen van uw browser. Voor meer gedetailleerde informatie
            over cookiebeheer in specifieke webbrowsers, kunt u de respectieve
            websites van de browsers raadplegen. Wat zijn cookies?
          </p>
          <h2>Informatie voor kinderen</h2>
          <p>
            Een ander belangrijk onderdeel van onze prioriteit is het beschermen
            van kinderen tijdens het gebruik van internet. Wij moedigen ouders
            en voogden aan om het online gedrag van kinderen te observeren,
            eraan deel te nemen en/of toezicht te houden en begeleiding te
            bieden.
            <br />
            topcasinonl.com verzamelt niet bewust persoonlijk identificeerbare
            informatie van kinderen onder de 13 jaar. Als u denkt dat uw kind
            deze soort informatie op onze website heeft verstrekt, raden wij u
            sterk aan om onmiddellijk contact met ons op te nemen. Wij zullen
            ons best doen om dergelijke informatie zo snel mogelijk uit onze
            administratie te verwijderen.
          </p>
          <h2>Alleen online Privacybeleid</h2>
          <p>
            Dit Privacybeleid is alleen van toepassing op onze online
            activiteiten en is geldig voor bezoekers van onze website met
            betrekking tot de informatie die zij delen en/of verzamelen op
            topcasinonl.com. Dit beleid is niet van toepassing op informatie die
            offline of via andere kanalen dan deze website is verzameld.
          </p>
          <h2>Toestemming</h2>
          <p>
            Door onze website te gebruiken, stemt u hierbij in met ons
            Privacybeleid en gaat u akkoord met de voorwaarden ervan.
          </p>
        </section>
      </main>
    </>
  );
};

export default Privacy;
