import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";
import logo from "../../images/logo.svg";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  function handleClick() {
    setIsActive(false);
  }

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
    setIsActive(false);
  };

  return (
    <header className="topcasinonl-header">
      <div className="topcasinonl-header__body pad">
        <Link to="/" className="topcasinonl-header__logo">
          <img
            src={logo}
            alt="logo"
            title="logo"
            className="topcasinonl-header__logo"
            width="142"
          />
        </Link>
        <div className="opcasinonl-header__navigation">
          <Link to="/" className="topcasinonl-header__link">
            Thuis
          </Link>
          <Link to="/games" className="topcasinonl-header__link">
            Onze spellen
          </Link>
          <a
            href="/#contacts"
            className="best-header__link"
            onClick={(e) => {
              handleNavigation(e, "/", "contacts");
            }}
          >
            Contact
          </a>
        </div>
        <div className="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="topcasinonl-burger__icon"
            onClick={() => setIsActive(true)}
            width="50"
          />
          <nav
            className={`burger-menu__nav ${isActive ? "active" : ""}`}
            id="nav"
          >
            <img
              src={closeBtn}
              className="burger__close-btn"
              id="close-icon"
              alt="close menu icon"
              onClick={handleClick}
            />
            <div className="burger-menu__nav-container">
              <Link
                to="/"
                className="topcasinonl-header__link"
                onClick={handleClick}
              >
                Thuis
              </Link>
              <Link
                to="/games"
                className="topcasinonl-header__link"
                onClick={handleClick}
              >
                Onze spellen
              </Link>
              <a
                href="/#contacts"
                className="best-header__link"
                onClick={(e) => {
                  handleNavigation(e, "/", "contacts");
                }}
              >
                Contact
              </a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
