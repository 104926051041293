import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="topcasinonl-footer">
      <div className="topcasinonl-footer__body pad">
        <img src={age} title="age" alt="age" width="54" height="53" className="topcasinonl-footer__age"/>

        <div className="topcasinonl-footer__left">
          <p className="t-24">Vrijwaring:</p>
          <p>
            Alle gebruikers moeten achttien jaar of ouder zijn om dit platform
            te gebruiken. Tenpin Wins is een entertainment-gericht platform dat
            geen gokopties met echt geld biedt. Spelers kunnen op deze site geen
            echt geld of enige vorm van valuta winnen. Alle spellen zijn gratis
            te spelen, ontwikkeld door Playtech, en werken uitsluitend met
            virtuele valuta die geen contante waarde heeft. Wij promoten
            verantwoord genieten van onze spellen.
          </p>
        </div>

        <div className="topcasinonl-footer__right">
          <p>
            topcasinonl.com - maakt gebruik van cookies om u maximaal gemak te
            garanderen. Door verder te bladeren op de site, gaat u akkoord met
            ons gebruik van cookies.
            <Link to="/cookies" className="topcasinonl-footer__link link-more">
              Meer informatie
            </Link>
          </p>

          <Link to="/privacy" className="topcasinonl-footer__link link-privacy">
            Privacybeleid
          </Link>
          <p>© Auteursrecht 2025 topcasinonl.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
