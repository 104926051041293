import React from "react";
import "./GamePage.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import imgOne from "../../images/bull-1.webp";
import imgTwo from "../../images/bull-2.webp";

const GameThree = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/bull" && (
        <Helmet>
          <title>Stier op een rodeo - Tem de stier en win grote prijzen!</title>
          <meta
            name="description"
            content="Stap in de rodeo ring en ga de uitdaging aan! Bull in a Rodeo biedt spannende actie, gratis spins en enorme winsten voor degenen die durven te rijden."
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-game-page">
          <div className="topcasinonl-game-page__body pad">
            <h1 className="text-center topcasinonl-main__header col-black">
              Bull in a Rodeo
            </h1>
            <iframe
              title="Bull in a Rodeo Overview"
              className="topcasinonl-game-page__frame"
              src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;gid=bullinarodeo&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%25&amp;height=100%25&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False"
            ></iframe>
          </div>
        </section>
        <section className="topcasinonl-advantages pad">
          <h2>Over het spel</h2>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Beschrijving</p>
            <p>
              Bull in a Rodeo is een online slot ontwikkeld door Play’n Go. Het
              is een 5-reel slot met 20 winlijnen.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Thema en afbeeldingen</p>
            <p>
              Rodeo's zijn iconisch. De cowboys en cowgirls met hun hoeden, de
              juichende menigte, die bedwelmende mix van opwinding en gevaar.
              Oorspronkelijk een manier om de vaardigheden van het hoeden van
              vee te oefenen, verspreidde het zich door het Westen, in Canada,
              Mexico en de VS. Bull in a Rodeo is duidelijk geïnspireerd op de
              meest herkenbare en opwindende aspecten van deze trotse traditie,
              van de laarzen tot de alcohol.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">RTP en variantie</p>
            <p>
              Dit is niet zo eenvoudig als je zou denken. Sommige gratis casino
              slots hebben verschillende RTP's, afhankelijk van het casino. Op
              zijn hoogst, een cijfer van 96,2%, plaatst Bull in a Rodeo tussen
              de beste online slots; op zijn laagst, kan het dalen tot een
              extreem teleurstellende 84,2%. Dit is waarschijnlijk een goed
              moment om je eraan te herinneren dat RTP een theoretisch
              gemiddelde is, dat openstaat voor variatie tussen spins.
            </p>
          </div>
          <div className="topcasinonl-grid topcasinonl-game-page__img">
            <img src={imgOne} title="bull game 1" alt="bull game 1" />
            <img src={imgTwo} title="bull game 2" alt="bull game 2" />
          </div>
        </section>
      </main>
    </>
  );
};

export default GameThree;
