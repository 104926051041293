import React from "react";
import "./GamePage.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import imgOne from "../../images/action-1.webp";
import imgTwo from "../../images/action-2.webp";

const GameFour = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/action" && (
        <Helmet>
          <title>Action Bank - Ontgrendel rijkdom met elke draai!</title>
          <meta
            name="description"
            content="Maak je klaar voor een energieke ervaring met Action Bank! Geniet van een spel vol spannende bonusfuncties, gratis spins en veel kansen om groot te winnen!"
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-game-page">
          <div className="topcasinonl-game-page__body pad">
            <h1 className="text-center topcasinonl-main__header col-black">
              Action Bank
            </h1>
            <iframe
              title="Action Bank Overview"
              className="topcasinonl-game-page__frame"
              src="https://dnk-resource.wimobile.casinarena.com/resource-service/game.html?game=actionbank_prt&amp;partnercode=mockpartner&amp;realmoney=false&amp;gaffing=true&amp;demo=true"
            ></iframe>
          </div>
        </section>
        <section className="topcasinonl-advantages pad">
          <h2>Over het spel</h2>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Beschrijving</p>
            <p>
              Action Bank is een online slot ontwikkeld door Barcrest / Light &
              Wonder. Het is een 5x3, 20-payline slot met een klassiek slot
              thema.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Thema en afbeeldingen</p>
            <p>
              Action Bank werd oorspronkelijk ontwikkeld door Barcrest slots,
              die later werden gekocht door Light & Wonder. De rollen en
              symbolen op deze klassieke slot springen eruit tegen een effen
              grijze achtergrond, wat lijkt op een kluis in een bank. De
              potentiële winnende symbolen zijn vet en helder en bestaan ​​uit
              een eenvoudige selectie van Os, Bars, Xs, scatters en wilds.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">RTP en variantie</p>
            <p>
              De RTP van deze keuze van Barcrest slots is 95,16%. De
              volatiliteit wordt beoordeeld als medium.
            </p>
          </div>
          <div className="topcasinonl-grid topcasinonl-game-page__img">
            <img src={imgOne} title="action game 1" alt="action game 1" />
            <img src={imgTwo} title="action game 2" alt="action game 2" />
          </div>
        </section>
      </main>
    </>
  );
};

export default GameFour;
