import React from "react";
import "./Main.css";
import Casinos from "../Casinos/Casinos";
import { Helmet } from "react-helmet-async";
import mainImg from "../../images/main-img.webp";

import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <>
      <Helmet>
        <title>
          De beste nieuwe en veilige online casino's van Nederland in 2025{" "}
        </title>
        <meta
          name="description"
          content="Ontdek de beste online casino's in Nederland in 2025! Alleen wij beschikken over veel nuttige informatie, gedetailleerde beoordelingen en gelicentieerde platforms."
        />
      </Helmet>
      <main className="topcasinonl-main">
        {/* <MainPopup/> */}
        <section className="topcasinonl-main__cover">
          <div className="topcasinonl-main__body pad">
            <div className="topcasinonl-main__text">
              <h1 className="topcasinonl-main__header col-black">
                Het beste online casino van Nederland in 2025
              </h1>
              <div className="topcasinonl-main__text-with-img">
                <p className="col-black">
                  Welkom op topcasinonl.com, jouw betrouwbare gids naar de beste
                  online casino's van Nederland. Wij hebben het voor jou
                  gemakkelijk gemaakt om de perfecte speelbestemming te vinden.
                  Onze selectie is gebaseerd op veiligheid, betrouwbaarheid en
                  plezier, zodat jij met een gerust hart kunt genieten van je
                  favoriete spellen.
                </p>
                <img
                  src={mainImg}
                  title="kabouter met een zak geld"
                  alt="kabouter"
                />
              </div>
            </div>
            <img
              src={mainImg}
              title="kabouter met een zak geld"
              alt="kabouter"
              className="topcasinonl-main__img"
            />
          </div>
        </section>
        <Casinos />
        <section className="topcasinonl-flex pad mg-top">
          <h2>De geschiedenis van online casino's</h2>
          <p>
            Online casino's hebben een rijke geschiedenis die begon in de jaren
            90, toen het internet zijn intrede deed. De eerste online casino's
            waren eenvoudig, maar al snel groeiden ze uit tot geavanceerde
            platformen met een breed aanbod aan spellen. Tegenwoordig bieden
            online casino's niet alleen klassieke spellen zoals roulette en
            blackjack, maar ook innovatieve slots, live dealer games en zelfs
            virtuele reality-ervaringen.
          </p>
          <p className="low-mg">
            De populariteit van online casino's is de afgelopen jaren enorm
            gestegen, mede dankzij de technologische vooruitgang en de
            toenemende behoefte aan entertainment thuis. Met eenvoudige toegang
            via smartphones en tablets kun je nu overal en altijd genieten van
            een casino-ervaring.
          </p>
        </section>
        <section className="topcasinonl-flex pad mg-top">
          <h2>Online casino's vs. traditionele casino's</h2>
          <p>
            Hoewel beide soorten casino's entertainment bieden, zijn er enkele
            belangrijke verschillen die ze uniek maken.
          </p>
          <div className="topcasinonl-grid">
            <div className="topcasinonl-grid__part">
              <p className="t-24 col-black">Online casino's:</p>
              <ul className="topcasinonl-grid__part-list">
                <li className="col-black">
                  Spelen kan overal en altijd, zolang je maar een
                  internetverbinding hebt.
                </li>
                <li className="col-black">
                  Een breed aanbod aan spellen, van klassieke tafelspellen tot
                  moderne slots en live dealer games.
                </li>
                <li className="col-black">
                  Veel online casino's bieden aantrekkelijke bonussen en
                  promoties om nieuwe spelers te verwelkomen en bestaande
                  spelers te belonen.
                </li>
                <li className="col-black">
                  Perfect voor wie op zoek is naar gemak, variatie en de
                  mogelijkheid om in eigen tempo te spelen.
                </li>
              </ul>
            </div>
            <div className="topcasinonl-grid__part">
              <p className="t-24 col-black">Traditionele casino's:</p>
              <ul className="topcasinonl-grid__part-list">
                <li className="col-black">
                  Bieden een unieke sfeer met levendige omgevingen, echte
                  dealers en sociale interactie.
                </li>
                <li className="col-black">
                  Geschikt voor wie houdt van de traditionele casino-ervaring en
                  de spanning van live spellen.
                </li>
                <li className="col-black">
                  Vaak gelegen in prachtige locaties, wat een bezoek aan een
                  casino tot een complete ervaring maakt.
                </li>
              </ul>
            </div>
          </div>
          <p>
            Social casinos are the best approach to enjoy risk-free gambling
            because of these elements along with a strong community aspect.
          </p>
        </section>
        <section className="topcasinonl-conclusion mg-top">
          <div className="topcasinonl-flex pad">
            <h2 className="text-center">Waarom kiezen voor topcasinonl.com?</h2>
            <p className="text-center">
              Bij topcasinonl.com vind je de beste online casino's van
              Nederland. Onze missie is om jou een veilige, plezierige en
              eerlijke speelervaring te bieden. Wij selecteren alleen casino's
              die voldoen aan strikte eisen op het gebied van licentie,
              veiligheid en spelerservaring.
            </p>
            <ul className="topcasinonl-conclusion__list">
              <li>
                <span className="fw-700">Licenties en veiligheid:</span> Wij
                controleren of elk casino een geldige licentie heeft en
                gebruikmaakt van de nieuwste beveiligingstechnologieën.
              </li>
              <li>
                <span className="fw-700">Eerlijke spellen:</span> We zorgen
                ervoor dat alle spellen gebruikmaken van gecertificeerde random
                number generators (RNG's) voor een eerlijke speelervaring.
              </li>
              <li>
                <span className="fw-700">Betrouwbare betalingen:</span> Wij
                testen populaire betaalmethoden om ervoor te zorgen dat
                stortingen en opnames snel en veilig verlopen.
              </li>
              <li>
                <span className="fw-700">Uitgebreid aanbod:</span> Of je nu
                houdt van slots, tafelspellen of live casino’s, wij helpen je
                het perfecte casino te vinden.
              </li>
            </ul>
          </div>
        </section>
      </main>
    </>
  );
};

export default MainCover;
