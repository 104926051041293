import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="topcasinonl-popup-cookie">
        <form className="topcasinonl-popup__form">
          <h2 className="text-center col-black">Cookiebeleid</h2>
          <p className="text-center col-black">
            We gebruiken cookies om uw ervaring te verbeteren en het
            websiteverkeer te analyseren. Als u doorgaat met browsen, gaan we
            ervan uit dat u het gebruik ervan accepteert. Voor meer informatie
          </p>
          <div className="topcasinonl-popup__btn-container">
            <button
              className="btn topcasinonl-popup__btn-agree"
              onClick={addPopupCookie}
            >
              Accepteren
            </button>
            <a
              href="/cookies"
              className="btn topcasinonl-popup__btn-more"
            >
              Meer informatie
            </a>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
