import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Cookies = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/cookies" && (
        <Helmet>
          <title>Cookiebeleid voor topcasinonl.com</title>
          <meta
            name="description"
            content="Op deze pagina leest u ons beleid over het gebruik van cookies, waarmee wij u een betere en veilige ervaring op de website kunnen bieden. Ontdek hoe u cookies kunt beheren."
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-privacy pad mg-top">
          <h1>Cookiebeleid voor topcasinonl.com</h1>
          <p>
            Dit is het Cookiebeleid voor topcasinonl.com, toegankelijk vanaf
            topcasinonl.com
          </p>
          <h2>Wat zijn cookies</h2>
          <p>
            Zoals gebruikelijk is bij bijna alle professionele websites, maakt
            deze site gebruik van cookies. Dit zijn kleine bestanden die naar uw
            computer worden gedownload om uw ervaring te verbeteren. Op deze
            pagina wordt beschreven welke informatie ze verzamelen, hoe we ze
            gebruiken en waarom we deze cookies soms moeten opslaan. We delen
            ook hoe u kunt voorkomen dat deze cookies worden opgeslagen, maar
            dit kan bepaalde elementen van de functionaliteit van de site
            verminderen of 'breken'.
          </p>
          <h2>Hoe we cookies gebruiken</h2>
          <p>
            We gebruiken cookies om verschillende redenen, zoals hieronder
            beschreven. Helaas zijn er in de meeste gevallen geen
            standaardopties binnen de industrie om cookies uit te schakelen
            zonder de functionaliteit en functies die ze aan deze site toevoegen
            volledig uit te schakelen. Het wordt aanbevolen om alle cookies
            ingeschakeld te laten als u niet zeker weet of u ze nodig heeft,
            aangezien ze mogelijk worden gebruikt om een dienst te leveren die u
            gebruikt.
          </p>
          <h2>Cookies uitschakelen</h2>
          <p>
            U kunt het instellen van cookies voorkomen door de instellingen in
            uw browser aan te passen (raadpleeg de Help-sectie van uw browser om
            te zien hoe u dit doet). Houd er rekening mee dat het uitschakelen
            van cookies de functionaliteit van deze en vele andere websites die
            u bezoekt zal beïnvloeden. Het uitschakelen van cookies zal meestal
            ook resulteren in het uitschakelen van bepaalde functionaliteiten en
            functies van deze site. Daarom wordt aanbevolen om cookies niet uit
            te schakelen.
          </p>
          <h2>De cookies die we instellen</h2>
          <p>
            Cookies gebruikt om de site te configureren. Om ervoor te zorgen dat
            u een goede ervaring heeft op deze site, bieden we een
            functionaliteit om uw voorkeuren in te stellen voor hoe deze site
            werkt wanneer u deze gebruikt. Om uw voorkeuren te onthouden, moeten
            we cookies instellen zodat deze informatie kan worden opgeroepen
            telkens wanneer u een pagina bezoekt die wordt beïnvloed door uw
            voorkeuren.
          </p>
          <h2>Cookies van derden</h2>
          <p>
            In sommige speciale gevallen gebruiken we ook cookies die door
            vertrouwde derden worden verstrekt. In de volgende sectie wordt
            gedetailleerd welke cookies van derden u via deze site kunt
            tegenkomen.
          </p>
          <p>
            Deze site gebruikt Google Analytics, een van de meest gebruikte en
            vertrouwde analysetools op het web, om ons te helpen begrijpen hoe u
            de site gebruikt en hoe we uw ervaring kunnen verbeteren. Deze
            cookies kunnen gegevens bijhouden zoals hoe lang u op de site
            doorbrengt en welke pagina's u bezoekt, zodat we boeiende content
            kunnen blijven produceren.
          </p>
          <p>
            Voor meer informatie over de cookies van Google Analytics, zie de
            officiële Google Analytics-pagina.
          </p>
          <h2>Meer informatie</h2>
          <p>
            Hopelijk heeft dit de zaken voor u verduidelijkt. Zoals eerder
            vermeld, als er iets is waarvan u niet zeker weet of u het nodig
            heeft of niet, is het meestal veiliger om cookies ingeschakeld te
            laten in het geval ze interageren met een van de functies die u op
            onze site gebruikt.
          </p>
        </section>
      </main>
    </>
  );
};

export default Cookies;
