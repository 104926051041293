import React from "react";
import "./GamePage.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import imgOne from "../../images/phoenix-1.webp";
import imgTwo from "../../images/phoenix-2.webp";

const GameTwo = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/phoenix" && (
        <Helmet>
          <title>
            Phoenix Up Cash - Sta op voor grote winsten en gratis spins!
          </title>
          <meta
            name="description"
            content="Laat de mythische Phoenix je torenhoge winsten bezorgen in Phoenix Up Cash! Geniet van opwindende bonusfuncties, gratis spins en de kans om legendarische beloningen te ontgrendelen."
          />
        </Helmet>
      )}
      <main>
        <section className="topcasinonl-game-page">
          <div className="topcasinonl-game-page__body pad">
            <h1 className="text-center topcasinonl-main__header col-black">
              Phoenix Up Cash
            </h1>
            <iframe
              title="Phoenix Up Cash Overview"
              className="topcasinonl-game-page__frame"
              src="https://d2drhksbtcqozo.cloudfront.net/casino/launcher.html?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;apex=1&amp;fullscreen=false"
            ></iframe>
          </div>
        </section>
        <section className="topcasinonl-advantages pad">
          <h2>Over het spel</h2>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Beschrijving</p>
            <p>
              Phoenix Up Cash is een online slot ontworpen door Relax Gaming en
              Trigger Button. De bonusfuncties omvatten gratis spins, instant
              cash wins en multipliers.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">Thema en afbeeldingen</p>
            <p>
              Omarm de mysteries van de oude mythologie terwijl je de Phoenix Up
              Cash slot opent, waar de legendarische Phoenix opstijgt in een
              mystieke, duistere omgeving. Deze game blaast leven in het verhaal
              van de Phoenix, zijn vlammende figuur die over het scherm zweeft,
              symboliseert de mogelijkheid van opwindende winsten. De levendige
              symbolen, waaronder vier edelstenen en verschillende
              bonussymbolen, verlichten de schaduwrijke achtergrond en voegen
              een kleurrijk contrast toe aan de griezelige atmosfeer.
            </p>
          </div>
          <div className="topcasinonl-advantages__part">
            <p className="t-24">RTP en variantie</p>
            <p>
              De Phoenix Up Cash slot heeft een RTP van 95,01% en hoewel dit
              prima is, is het wel wat laag vergeleken met wat we van de meeste
              online slots hier bij Slots Temple verwachten. De gemiddelde
              volatiliteit spreekt de meeste slotspelers aan, met een maximale
              winst van 10.000x de inzet die past bij de algehele dynamiek en
              game engine.
            </p>
          </div>
          <div className="topcasinonl-grid topcasinonl-game-page__img">
            <img src={imgOne} title="phoenix game 1" alt="phoenix game 1" />
            <img src={imgTwo} title="phoenix game 2" alt="phoenix game 2" />
          </div>
        </section>
      </main>
    </>
  );
};

export default GameTwo;
